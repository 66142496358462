.paginate{
    margin-top: 20px;
    float: right;
}
.pagination {
    list-style-type: none;
    display: block;
    li {
        display: inline-block;
        line-height: 20px;
        cursor: pointer;
        background-color: #fff;
        color: #2dce89;
        border: 1px solid #c1c1c1;
        border-right: none;
        font-size: 14px;
        a{
            padding: 5px 10px;
            display: block;
            outline: none;
        }
    }
    li:nth-last-child(1){
        border-right: 1px solid #c1c1c1;
    }
    .previous{
        margin-right: 10px;
        border-right: 1px solid #c1c1c1;
        font-size: 14px;
    }
    .next{
        margin-left: 10px;
        border-right: 1px solid #c1c1c1;
        font-size: 14px;
    }
    .active{
        background-color: #2dce89;
        color: #fff;
    }
}