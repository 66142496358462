.title{
    font-size: 24px;
    margin-top: 0;
    padding: 14px 20px;
    background-color: #327A81;
    color: aliceblue;
    border-radius: 10px;
}
.btn{
    margin-top: 30px;
}