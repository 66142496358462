.title{
    font-size: 24px;
    margin-top: 0;
    padding: 14px 20px;
    background-color: #327A81;
    color: aliceblue;
    border-radius: 10px;
}
.addButton{
    margin-bottom: 80px;
}
.table{
    width: 100%;
    border: 3px solid #91CED4;
    border-collapse: collapse;
    tr,th,td{
        border-bottom: 3px solid #91CED4;
        border-collapse: collapse;
        text-align: left;
        padding: 20px 10px;
        
    }
    .headTable{
        background-color: #91CED4;
        border: 3px solid #327A81;
        border-right: 4px solid #327A81;
    }
    .actionIcon{
        font-size: 24px;
    }
}
.spin{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 70px;
    left: 0;
    width: 100%;
    height: calc(100% - 70px); 
}
.navLink{
    background-color: rgb(32, 126, 208);
    color: #FFFFFF;
    padding: 10px 26px;
    margin-bottom: 20px;
    float: right;
    border-radius: 5px;
}
.actionIcon{
    font-size: 24px;
}
.search{
    padding: 20px;
    border: 20px solid #91CED4;
    margin-bottom: 10px;
    // border-radius: 20px;
}
.iconType{
    width: 50px;
    margin-right: 10px;
}
.typeService{
    font-weight: 700;
    padding-left: 10px;
    margin-bottom: 20px;
    font-size: 16px;
}