.title{
    font-size: 24px;
    margin-top: 0;
    padding: 14px 20px;
    background-color: #327A81;
    color: aliceblue;
    border-radius: 10px;
}
.avatar{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    border: #07af85 4px solid;
}
.actionIcon{
    font-size: 24px;
}
.table{
    width: 100%;
    border: 3px solid #91CED4;
    border-collapse: collapse;
    tr,th,td{
        border-bottom: 3px solid #91CED4;
        border-collapse: collapse;
        text-align: left;
        padding: 20px 10px;
        
    }
    .headTable{
        background-color: #91CED4;
        border: 3px solid #327A81;
        border-right: 4px solid #327A81;
    }
}
.spin{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 70px;
    left: 0;
    width: 100%;
    height: calc(100% - 70px); 
}
.search{
    padding: 20px;
    border: 20px solid #91CED4;
    margin-bottom: 10px;
    // border-radius: 20px;
}