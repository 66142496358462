.customSelect{
    .label{
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .select{
        margin-top: 10px;
        border: 1px solid #91CED4;
        border-radius: 7px;
    }
    width: 70%;
    padding: 10px 0;
    .requiredActive{
        color: red;
    }
}
