.input{
    padding: 10px 0;
    label{
        font-weight: bold;
        font-size: 16px;
    }
    input{
        margin-top: 6px;
        height: 34px;
        width: 70%;
        border: 1px solid #91CED4;
        border-radius: 6px;
        padding: 0 10px;
    }
    .requiredActive{
        color: red;
    }
}