.login{ 
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;  
    background-color: #1088ce;
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.5);
    }
    .header{
        text-align: center;
        padding: 20px 0;
        color: #333;
        .logo {
            width: 200px
        }
        >i{
            font-size: 112px;
        }
        >p{
            color: #dc3545;
            font-size: 14px;
            margin-top: 5px;
        }
        >img{
            max-width: 150px;
        }
    }
    .errors{
        color: #dc3545;
    }
    .body{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 50px 30px;
        z-index: 1;
        width: 450px;
        margin: 0 auto;
        background-color: #FFF;
        border-radius: 10px;
        img {
            width: 200px;
        }
        p {
            margin: 10px 0 20px 0;
            font-size: 14px;
            color: #999;
        }
        .formLogin{   
            width: 100%;
            form {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-radius: 5px; 
                label{
                    font-size: 13px;
                    font-weight: bold;
                    color: #333;
                    line-height: 30px;
                    display: block;
                    margin-top: 3px;
                }
                input[type="text"], input[type="password"]{
                    display: block;
                    width: 100%;
                    line-height: 35px;
                    border-radius: 3px;
                    padding: 0 10px;
                    border: 1px solid #c9c9c9;
                    color: #666;
                    margin-top: 20px;
                }
                input[type='submit']{
                    display: block;
                    font-size: 14px;
                    font-weight: bold;
                    color: #fff;
                    margin-top: 45px;
                    line-height: 40px;
                    padding: 0 100px;
                    border: none;
                    background-color: #5ec2de;
                    border-radius: 20px;
                    cursor: pointer;
                    &:hover{
                        opacity: 0.9;
                    }
                }
            }
        }
    }
}